import React from 'react'

const PluginScripts = () => (
  <div id="pluginScripts">
    <script src="js/jquery.js"></script>
    <script src="js/plugins.js"></script>
    <script src="js/functions.js"></script>
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-113903265-1"></script>
  </div>
)

export default PluginScripts
