
import React from 'react'
import demoLink from '../demoLink'

export default (props) => (
  <section id="testdrive">
    <div className="sectionDivider">
      <h1 className="text-center">
        <span style={{
          paddingRight: '6px',
        }}>
          Testdrive our system. It's easy
          <span style={{
            color: '#58c33f',
            fontWeight: 700,
          }}>
            {' '}(and fun){' '}
          </span>
        </span>
      </h1>
    </div>
    <div className="row clearfix sectionInstructions">
      <div className="col-sm-6 col-xl-3 center">
        <div className="instructions">
          <div className="center">
            <div className="stepNumber">
              Step 1
            </div>
            <img
              alt="From another phone, scan this code or go to owner.lylde.com"
              src="images/icon-employee-app-with-qr-code.svg"
            />
            <p>
              From your phone, scan the code or go to
              <demoLink.Owner />
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3 center">
        <div className="instructions">
          <div className="center">
            <div className="stepNumber">
              Step 2
            </div>
            <img
              alt="From your phone, scan this code or go to customer.lylde.com to get your card."
              src="images/icon-customer-app-with-qr-code.svg"
            />
            <p>
              From another phone, scan the code or go to
              <demoLink.Customer />
              for the card.
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3 center">
        <div className="instructions">
          <div className="center">
            <div className="stepNumber">
              Step 3
            </div>
            <img
              alt="Tap Scan card and scan the QR code on the first phone."
              src="images/icon-scan-customer-app.svg"
            />
            <p>
              Tap <b>Scan card</b> and scan the QR code on the second phone.
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3 center">
        <div className="instructions">
          <div className="center">
            <div className="stepNumber">
              Step 4
            </div>
            <img
              alt="Tap Add 1 Point, then Confirm. That's it!"
              src="images/icon-add-point.svg"
            />
            <p>Tap <b>Add 1 Point</b>, then <b>Confirm</b>. That's it!</p>
          </div>
        </div>
      </div>
    </div>
  </section>
)
