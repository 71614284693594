import React from 'react'
import ReactGA from 'react-ga'
import firebase from 'firebase/app'
import 'firebase/firestore'

import DemoCarousel from '../demoCarousel'

class SignUp extends React.Component {
  state = {
    submitted: false,
    email: '',
    submitting: false,
  }

  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit = () => {
    const {
      email,
    } = this.state
    console.log('submit email address')
    ReactGA.event({
      category: 'Contact',
      action: 'Clicked Submit Email',
    })
    this.setState({
      submitted: true,
    })
    if (!email) {
      return
    }
    this.setState({
      submitting: true,
    })
    firebase.firestore().collection('signups')
      .add(this.state)
      .then((result) => {
        this.setState({
          submitting: false,
          result: 'Thank you! We will contact you soon to get started with your new mobile punchcard app!',
        })
        console.log('signup result: ', result)
      })
      .catch(err => {
        console.error('signup error', err)
      })
  }

  render () {
    const {
      submitted,
      email,
      result,
      submitting,
    } = this.state
    return (

      <div id="content" className="container signUp mt-5">
        <div className="row mx-auto mt-5">
          <div className="col-sm-5 d-none d-sm-block">
            <DemoCarousel />
          </div>
          <div className="col-12 col-sm-7">
            <h2>Get started today</h2>
            <p>
              Please enter your email address. You'll receive a confirmation email soon!
            </p>
            <form
              name="form"
              onSubmit={(e) => {
                this.handleSubmit()
                e.preventDefault()
                return false
              }}
            >
              <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                <input placeholder="Email address" type="text" className="form-control col-sm-10" name="email" value={email} onChange={this.handleChange} />
                {submitted && !email &&
                  <div className="pl-2 text-danger help-block">Enter a valid email address.</div>
                }
              </div>
              <button
                type="button"
                className="btn btn-block btn-primary col-sm-10"
                onClick={this.handleSubmit}
              >
                Sign Me Up!
              </button>
              {submitting &&
                <div className="pt-2 col-sm-10 text-center">
                  <img
                    className="mx-auto"
                    alt="submitting"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                  />
                </div>
              }
              {submitted && result && (
                <div className="mt-2">{result}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default SignUp
