import React from 'react'

import { OutboundLink } from 'react-ga'

const Header = () => (
  <nav className="navbar fixed-top d-flex align-items-center">
    <div className="navbar-brand" />
    <div className="float-right d-flex align-items-center">
      <OutboundLink
        to="https://www.facebook.com/Loyalade-490475488119816/"
        target="blank" aria-label="Like us on Facebook!"
        className="btn-social facebook mr-2"
        eventLabel="facebook"
      >
          &nbsp;
      </OutboundLink>
      <OutboundLink
        to="https://www.instagram.com/loyalade_app/"
        target="blank"
        aria-label="Follow us on Instagram!"
        className="btn-social instagram mr-3"
        eventLabel="instagram"
      >
        &nbsp;
      </OutboundLink>
    </div>
  </nav>
)

export default Header
