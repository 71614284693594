
import React from 'react'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './index.css'

import { Carousel } from 'react-responsive-carousel'

export default () => (
  <Carousel autoPlay className="mb-3">
    <div>
      <img src="images/1.png" alt="Example loyalaty card - Loyalade Card"/>
    </div>
    <div>
      <img src="images/2.png" alt="Example loyalaty card - Restaurant"/>
    </div>
    <div>
      <img src="images/3.png" alt="Example loyalaty card - Restaurant" />
    </div>
    <div>
      <img src="images/4.png" alt="Example loyalaty card - Mechanic"/>
    </div>
    <div className="mb-3">
      <img src="images/5.png" alt="Example loyalaty card - Nail Salon" />
    </div>
  </Carousel>
)
