import React from 'react'

const Easy = (props) => (
  <section id="easy">
    <div className="container clearfix mt-4">
      <div className="row mb-3">
        <div className="col-md-4 center mb-2">
          <img
            alt="Here's how it works."
            className=" iconEasy"
            src="images/icon-easy.svg"
          />
        </div>
        <div className="col-md-8">
          <h2>Easy for you, <b className="boldGreenHighlight">fun for customers</b> </h2>
          <p>1. Customers install your free app to their home screen.</p>
          <p>2. You scan their card and give them points. That’s it!</p>
          <p>Once your customers have built up enough points, redeeming is just as simple!</p>
        </div>
      </div>
    </div>
  </section>
)

export default Easy
