
import React from 'react'

// var hideEnd = window.dataLayer && window.dataLayer.hide.end

export class Experiments extends React.Component {
  constructor (props) {
    super(props)
    const loading = Experiments.loading
    this.state = {
      loading,
    }
  }

  static loading = true;

  loaded () {
    Experiments.loading = false
    this.setState({ loading: false })
  }

  componentDidMount () {
    const { experiments } = this.props
    Object.keys(experiments).forEach((experiment) => {
      Variant.variantMap[experiment] = 0 // experiments[experiment].local
    })
    this.loaded()
    // if (hideEnd) {
    //   window.dataLayer.hide.end = () => {
    //     console.log('hide end')
    //     Object.keys(experiments).forEach((experimentName) => {
    //       const value = window.google_optimize.get(experiments[experimentName].id)
    //       if (value !== undefined) {
    //         Variant.variantMap[experimentName] = 0 // Number(value)
    //       } else {
    //         console.error(`No variant selected for ${experimentName}, default to 0`)
    //         Variant.variantMap[experimentName] = 0
    //       }
    //     })
    //     this.loaded()
    //     hideEnd()
    // //   }
    // }
  }

  render () {
    const { loading } = this.state
    if (loading) {
      return null
    }
    const { children } = this.props
    return children
  }
}

export class Variant extends React.Component {
  static variantMap = {};

  render () {
    const { experiment, variant, children } = this.props
    console.log(Variant.variantMap, variant)
    if (Variant.variantMap[experiment] === variant) {
      return children
    }
    if (!Object.hasOwnProperty.call(Variant.variantMap, experiment) && variant === 0) {
      console.error(`Defaulting to variant 0`)
      return children
    }
    return null
  }
}
