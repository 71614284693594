import React from 'react'
import Easy from '../content/easy'
import Customized from '../content/customized'
import TestDrive from '../content/testdrive'
import Testimonials from '../content/testimonials'
import NoMatterYourBusiness from '../content/nomatteryourbusiness'
import AnAppFor from '../content/anappfor'
import StartRewarding from '../content/startrewarding'
import { Variant } from '../variant'

import '../../css/bootstrap.css'
import '../../css/style.css'
import '../../css/swiper.css'
import '../../css/dark.css'
import '../../css/font-icons.css'
import '../../css/animate.css'
import '../../css/responsive.css'
import '../../css/magnific-popup.css'
import '../../css/custom.css'

const Content = () => (
  <section id="content">
    <Variant experiment="simple" variant={0}>
      <Easy />
      <Customized />
      <TestDrive />
      <Testimonials />
      <NoMatterYourBusiness />
      <AnAppFor />
      <StartRewarding />
    </Variant>
    <div id="footer" className="footer fixed-bottom">
      <p>&copy; 2019 Loyalde, LLC. All rights reserved.</p>
    </div>
  </section>

)

export default Content
