
import React from 'react'
import DemoCarousel from '../demoCarousel'

export default (props) => (
  <section id="customized">
    <div className="container clearfix mt-4">
      <div className="row">
        <div className="col-12 col-sm-4">
          <DemoCarousel />
        </div>
        <div className="col-12 col-sm-8 pt-4 pb-4">
          <h2>Customized for <b className="boldGreenHighlight">your business</b></h2>
          <p className="mb-4">
            Other systems force you to look like everyone else. Not Loyalade. We designed our system so your
            app is just that &mdash; yours.
          </p>
          <h2> <b className="boldGreenHighlight">We do everything</b> for you</h2>
          <p>
            Don't worry about complex systems and online builders. We work with you so your card has the look you want.
          </p>
          <p>Here are some of the customizations for your app:</p>
          <ul className="normalBullets">
            <li>
              <p>
                Home screen icon and splash screen. Customers see your branding, not some other company.
              </p>
            </li>
            <li>
              <p>
                Colors of all elements, fonts, etc
              </p>
            </li>
            <li>
              <p>
                Header and side menu
              </p>
            </li>
            <li>
              <p>
                QR code positioning and coloring
              </p>
            </li>
            <li>
              <p>
                Punches (the icons customers collect to redeem offers)
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)
