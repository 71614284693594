import React from 'react'
import ReactDOM from 'react-dom'
import { ParallaxProvider } from 'react-scroll-parallax'
import firebase from 'firebase/app'
import ReactGA from 'react-ga'
import createHistory from 'history/createBrowserHistory'
import { Router } from 'react-router-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './ga'
import { Experiments } from './components/variant'
import experiments from './experiments'

const trackPage = () => {
  const location = window.location
  console.log('track page ', location.pathname)
  ReactGA.pageview(location.pathname + location.search)
}
trackPage()
const history = createHistory()
history.listen(trackPage)

firebase.initializeApp(require('./firebase_config'))

const firestore = firebase.firestore()
const settings = { timestampsInSnapshots: true }
firestore.settings(settings)

ReactDOM.render(
  <Experiments
    experiments={experiments}
  >
    <Router history={history}>
      <ParallaxProvider>
        <App />
      </ParallaxProvider>
    </Router>
  </Experiments>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
