
import React from 'react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

export default (props) => (
  <section id="startrewarding">
    <div className="sectionDivider">
      <h1
        className="text-center"
        style={{
          marginBottom: 0,
        }}
      >
        Start rewarding customers now.
        <br />
        Ready to get started?
        <br />
        <Link to="/signup">
          <button
            className="mt-4 btn btnHeaderCTA btnPressed"
            onClick={() => {
              ReactGA.event({
                category: 'Contact',
                action: 'Clicked Trailer Get Started',
              })
            }}
          >
            Get Started
          </button>
        </Link>
      </h1>
    </div>
  </section>
)
