import React from 'react'
import PropTypes from 'prop-types'
import { OutboundLink } from 'react-ga'
import {
  isMobile,
} from 'react-device-detect'

function DemoLink (props) {
  const { url, eventLabel } = props
  if (isMobile) {
    return (
      <OutboundLink
        eventLabel={eventLabel}
        to={'http://' + url}
      >
        {' '}{url}{' '}
      </OutboundLink>
    )
  }
  return (
    <b>{' '}{url}{' '}</b>
  )
}

DemoLink.propTypes = {
  url: PropTypes.string.isRequired,
  eventLabel: PropTypes.string.isRequired,
}

function Owner () {
  return (<DemoLink url="owner.lylde.com" eventLabel="ownerDemo" />)
}

function Customer () {
  return (<DemoLink url="customer.lylde.com" eventLabel="customerDemo" />)
}

export default {
  Owner,
  Customer,
}
