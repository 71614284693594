
import React from 'react'

export default (props) => (
  <section id="nomatteryourbusiness">
    <div className="sectionDivider">
      <h1 className="text-center">
        Use the Loyalade system to reward customers no matter your business.
      </h1>
    </div>
    <div className="container clearfix mt-4">
      <div className="row">
        <div className="col-md-4 col-sm-6 mb-3 text-center">
          <div className="businessIcon">
            <div className="food"></div>
            <span></span>
            <h2 className="businessLabel text-center mt-3">
              <span>
                Restaurants, cafes,
                <br />
                and food trucks
              </span>
            </h2>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 mb-3 text-center">
          <div className="businessIcon">
            <div className="bars"></div>
            <span></span>
            <h2 className="businessLabel text-center mt-3">
              <span>
                Bars and
                <br />
                entertainment venues
              </span>
            </h2>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 mb-3 text-center">
          <div className="businessIcon">
            <div className="hair"></div><span></span>
            <h2 className="businessLabel text-center mt-3">
              <span>
                Salons, boutiques,
                <br />
                and spas
              </span>
            </h2>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 mb-3 text-center">
          <div className="businessIcon">
            <div className="auto"></div>
            <span></span>
            <h2 className="businessLabel text-center mt-3">
              <span>
                Auto service and repair
              </span>
            </h2>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 mb-3 text-center">
          <div className="businessIcon">
            <div className="specialty"></div>
            <span></span>
            <h2 className="businessLabel text-center mt-3">
              <span>
                Craft and specialty stores
              </span>
            </h2>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 mb-3 text-center">
          <div className="businessIcon">
            <div className="retail"></div>
            <span></span>
            <h2 className="businessLabel text-center mt-3">
              <span>
                Retail establishments
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>
)
