import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import Header from './components/index/header'
import BuildLoyalty from './components/index/buildloyalty'
import PluginScripts from './components/index/pluginScripts'
import Content from './components/index/content'
import SignUp from './components/signUp'

class App extends Component {
  render () {
    return (
      <div className="App">
        <Header />
        <Route exact path="/signup" render={() => (
          <SignUp />
        )} />
        <Route exact path="/" render={BuildLoyalty} />
        <Route exact path="/" render={Content} />
        <PluginScripts />
      </div>
    )
  }
}

export default App
