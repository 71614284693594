
import React from 'react'

export default (props) => (
  <section id="anappfor">
    <div className="sectionDivider">
      <h1 className="text-center">
        Free for your customers. Priceless for your business.
      </h1>
    </div>
    <div className="row clearfix common-height">
      <div className="col-lg-6 col-md-6 center sectionYellow">
        <div className="col-lg-9 offset-lg-3">
          <div className="heading-block nobottomborder">
            <h2 className="yellowGreenSections">An app for your customers...</h2>
          </div>
          <div className="center iconEmployeeApp">
            <img
              alt="an app for your business."
              src="images/icon-customer-app.svg"
            />
          </div>
          <div className="sectionBenefits">
            <p>Fun and easy to use</p>
            <p>No installation or app download</p>
            <p>No paper card to ruin, lose, or forget</p>
            <p>No login or passwords</p>
            <p>No registration</p>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 center sectionGreen">
        <div className="col-lg-9">
          <div className="heading-block nobottomborder">
            <h2 className="yellowGreenSections">...an app for your business.</h2>
          </div>
          <div className="center iconEmployeeApp">
            <img
              alt="an app for your business."
              src="images/icon-employee-app.svg"
            />
          </div>
          <div className="sectionBenefits">
            <p>No-hassle one-time log in</p>
            <p>Fast and secure reward system</p>
            <p>Customized to match your branding</p>
            <p>Unlimited customers per location</p>
            <p>Customers see your logo</p>
          </div>
        </div>
      </div>
    </div>
  </section>
)
