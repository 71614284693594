import React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

import { Variant } from '../variant'

const BuildLoyalty = () => (
  <section
    className="slider-element slider-parallax swiper_wrapper full-screen clearfix"
    id="slider"
  >
    <ParallaxBanner
      layers={[
        {
          image: 'images/header-main-dark.jpg',
          amount: 0.2,
          slowerScrollRate: true,
        },
      ]}
    >
      <div className="headerInfo mx-auto d-flex flex-column align-items-center justify-content-center position-relative">
        <h2
          className="text-center fadeInDown animated animDelay500ms"
          data-animate="fadeInDown"
          data-caption-animate="fadeInDown"
        >
          <Variant experiment="simple" variant={0}>
            Build loyalty.
            <br/>
            Build your business.
          </Variant>
          <Variant experiment="simple" variant={1}>
            A mobile punch card for your business. <br />$47 per month
          </Variant>
        </h2>
        <Link to="/signup">
          <button
            data-animate="fadeInUp"
            data-caption-animate="fadeInUp"
            className="mt-3 btn btn-lg btnHeaderCTA btnPressed fadeInUp animated animDelay500ms"
            onClick={() => {
              ReactGA.event({
                category: 'Contact',
                action: 'Clicked Build Loyalty Get Started',
              })
            }}
          >
            <Variant experiment="simple" variant={0}>
              Get Started
            </Variant>
            <Variant experiment="simple" variant={1}>
              Get your app
            </Variant>
          </button>
        </Link>
      </div>
    </ParallaxBanner>
  </section>
)

export default BuildLoyalty
