
import React from 'react'

export default (props) => (
  <section id="testimonials">
    <div className="sectionDivider mb-5">
      <h1 className="text-center">Everyone loves using Loyalade, but don't take our word for it.</h1>
    </div>
    <div className="container clearfix">
      <div className="row">
        <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-3 mb-5">
          <a
            href="https://www.youtube.com/watch?v=Ms58z9JGlbc"
            data-lightbox="iframe"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="thumbnailVideo" src="images/thumbnail-vid.jpg" alt="Video" />
            <div className="overlay">
              <div className="overlay-wrap"></div>
            </div>
          </a>
        </div>
        <div
          className="col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-3"
          data-thumb-alt=""
          style={{
            marginRight: '0px',
            'float': 'left',
            'display': 'block',
          }}>
          <div className="testi-image">
            <img alt="Customer Testimonails" draggable="false" src="images/avatar-shams.jpg" />
          </div>
          <div className="mb-4 testi-content">
            <p>The app gives customers incentive to come back to get points. As they come back, we get to know them better and build a relationship.</p>
            <div className="testi-meta">
              Shams
              <span>
                Manager, Kasbah Moroccan Lounge
                <br />
                Austin, Texas
              </span>
            </div>
          </div>
          <div
            className="mb-4"
            data-thumb-alt=""
          >
            <div className="testi-image">
              <img alt="Customer Testimonails" draggable="false" src="../images/avatar-ali.jpg" />
            </div>
            <div className="testi-content">
              <p>I use the app every time I come here. Sometimes three or four times in one night!</p>
              <div className="testi-meta">
                Ali
                <span>
                  Patron, Kasbah Moroccan Lounge
                  <br />
                  Austin, Texas
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
